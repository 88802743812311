/* eslint-disable camelcase */
import { createReducer } from '@reduxjs/toolkit'
import * as actions from './action'

export type IUserOlympiadData = {
  data: {
    key: string
    last_modified: string
    size: number | null
    storage_class: string
  }[]
  isFetching: boolean
  isError: boolean
  errorMessage: string
}

const initialState: IUserOlympiadData = {
  data: [
    {
      key: '',
      last_modified: '',
      size: null,
      storage_class: '',
    }
  ],
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const userOlympiadData = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getUserOlympiadDataByFractalIdRequest, (state: IUserOlympiadData): IUserOlympiadData => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getUserOlympiadDataByFractalIdSuccess, (state: IUserOlympiadData, action: { payload: any }): IUserOlympiadData => ({
      ...state,
      data: action.payload,
      isFetching: false,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getUserOlympiadDataByFractalIdRejected, (state: IUserOlympiadData, action: { payload: any }): IUserOlympiadData => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload.message
    }))

})

export default userOlympiadData
