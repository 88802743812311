
import React from 'react'

// Components
import { Box } from '@mui/system'
// import AwardsCardMobile from '../../components/card/AwardsCardMobile'
import { Tab, Tabs } from '@mui/material'
import ListWithSearchMobile from '../../components/list/ListSearchMyOlympiadsMobile'
import ListSearchActiveOlympiadsMobile from '../../components/list/ListSearchActiveOlympiadsMobile'

// Styles
import { useTheme } from '@mui/material/styles'

// Utils
import { rows } from '../../utils/mockup'
import { useAppSelector } from '../../store/hooks'

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const HomeMobileContainer = () => {
  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  const { data, isFetching } = useAppSelector((state) => state.userOlympiadData)

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingInline: '16px'
      }}
    >
      {/* <Box
        sx={{
          marginTop: '16px',
          paddingRight: '82px'
        }}
      >
        <AwardsCardMobile participation={30} participationPercentage={100} />
      </Box> */}
      <Box pt='32px'>
        <Box
          sx={{
            borderBottom: 2,
            borderColor: 'divider',
            '& .MuiTabs-indicator': {
              backgroundColor: `${theme.palette.text.primary} !important`
            },
          }}
        >
          <Tabs
            sx={{
              '& .Mui-selected': {
                color: `${theme.palette.text.primary} !important`,
                fontSize: '24px',
                fontWeight: 600
              },
              '& .MuiTab-root': {
                color: `#CFCECE`,
                fontSize: '24px',
                fontWeight: 600
              }
            }}
            value={value}
            onChange={handleChange}
            aria-label='olysTab'
          >
            <Tab

              label='Atuais'
              {...a11yProps(0)}
            />
            {/* <Tab

              label='Disponíveis'
              {...a11yProps(1)}
            /> */}
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <ListWithSearchMobile isFetching={isFetching} data={data} />
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <ListSearchActiveOlympiadsMobile data={rows} />
        </CustomTabPanel>
      </Box>
    </Box>
  )
}

export default HomeMobileContainer