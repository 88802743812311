
import React from 'react'
import { RouteProps } from 'react-router-dom'
import NonLoggedPage from '../pages/NonLoggedPage'
import LoginPage from '../pages/LoginPage'
import Home from '../pages/HomePage'
import DashboardTemplate from '../components/template/DashboardTemplate'
import HistoryPage from '../pages/HistoryPage'
import OlympiadDetailsPage from '../pages/OlympiadDetailsPage'
/**
 * Only logged users can access these routes
 *
 * - Major precedence when user IS LOGGED:
 *    Overwrites the same paths of the public routes
 * - No match when user IS NOT LOGGED:
 *    Render LoginPage component by default
 */

export interface Route extends Omit<RouteProps, "component"> {
  path: string,
  component: React.ElementType,
  template?: React.ElementType,
  default?:boolean,
  onlyNoAuth?: boolean,
  key?: string,
  exact?: boolean
}

export const privateRoutes: Route[] = [
  {
    path: '/',
    component: Home,
    default: true,
    template: DashboardTemplate
  },
  {
    path: '/history',
    component: HistoryPage,
    template: DashboardTemplate
  },
  {
    path: '/olympiad-details/:id',
    component: OlympiadDetailsPage,
    template: DashboardTemplate
  },

]

/**
 * Only not logged users can access these routes
 *
 * - Precedence over same paths of private routes when user IS NOT LOGGED:
 *    Overwrites the login rendering
 * - No match when user IS LOGGED
 *    Redirect to default private route
 */
export const notLoggedRoutes: Route[] = [
  {
    path: '/',
    component: NonLoggedPage,
    onlyNoAuth: true
  },
  {
    path: '/login',
    component: LoginPage,
    onlyNoAuth: true
  }
]