import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'
import OlympiadDetailsMobile from '../containers/olympiadDetails/OlympiadDetailsMobile'
import OlympiadDetailsDesktopContainer from '../containers/olympiadDetails/OlympiadDetailsDesktopContainer'
const OlympiadDetailsPage = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      {
        smDown ? <OlympiadDetailsMobile /> : <OlympiadDetailsDesktopContainer />
      }
    </div>
  )
}

export default OlympiadDetailsPage