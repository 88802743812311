export const olysLeagueAppUrl: { [key: string]: string } = {
    local: 'http://localhost:8000',
    staging: 'https://stag-app.ligadasolimpiadas.com.br',
    production: 'https://app.ligadasolimpiadas.com.br',
}

export const ssoUrl: { [key: string]: any } = {
    local: 'http://localhost:8001/',
    staging: 'https://staging-fractalid.fractaltecnologia.com.br',
    production: 'https://fractalid.fractaltecnologia.com.br'
}

export const OlysLandingPages = [
    {
        name: 'pocket',
        url: 'https://olimpiadapocket.com.br/'
    },
    {
        name: 'sapientia',
        url: 'https://olimpiadadofuturo.com.br/'
    },
    {
        name: 'vitalis',
        url: 'https://olimpiadademedicina.org/'
    },
    {
        name: 'obl',
        url: 'https://obling.org/'
    },
]