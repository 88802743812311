import React from 'react'
import { Box } from '@mui/system'
import { IconButton, Typography } from '@mui/material'
import { useDrawerContext } from '../../contexts/DrawerContext'
import { useTheme } from '@mui/material/styles'
import MenuIcon from '../../assets/header/menu-icon.svg'
import { useLocation } from 'react-router-dom'

const Header = () => {
  const { toggleDrawerOpen } = useDrawerContext()
  const theme = useTheme()
  const location = useLocation()
  const path = location?.pathname

  let title = ''

  switch (path) {
    case '/':
      title = 'Liga das Olimpíadas'
      break
    case '/history':
      title = 'Histórico de Olimpíadas'
      break;
    default:
      title = 'Liga das Olimpíadas'
      break
  }
  return (
    <Box
      sx={{
        alignItems: 'center',
        background: '#fff',
        display: 'flex',
        height: '64px',
        paddingInline: '16px',
        position: 'fixed',
        width: '100%',
        zIndex: 999
      }}
      component='header'
    >
      <IconButton title='Abrir menu' onClick={toggleDrawerOpen}>
        <img src={MenuIcon} />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Typography
          component='h1'
          sx={{
            color: theme.palette.text.primary,
            fontSize: '18px',
            flex: 1,
            fontWeight: 600,
            lineHeight: '22px',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box >
  )
}

export default Header