import * as actions from './action'
import { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getUserOlympiadDataService } from './service'

export function* getUserOlympiadDataSaga({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(getUserOlympiadDataService, payload)
    if (response.status === 200) {
      yield put(actions.getUserOlympiadDataByFractalIdSuccess(response?.data))
    }
  } catch (error) {
    yield put(actions.getUserOlympiadDataByFractalIdRejected(error))
  }
}

// Watchers
export function* watchGetUserOlympiadDataSaga() {
  yield takeLatest(actions.getUserOlympiadDataByFractalIdRequest.type, getUserOlympiadDataSaga)
}

export default function* userOlympiadDataSagas() {
  yield all([
    watchGetUserOlympiadDataSaga()
  ])
}
