import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
// import { ReactComponent as OrderIcon } from '../../assets/home/ordernate-icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/history/table/calendar-icon.svg'
import SearchIcon from '../../assets/home/search-icon.svg'

// Components
import {
  Button,
  LinearProgress,
  List,
  ListItem,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'

// Styles 
import { useTheme, styled } from '@mui/material/styles'
import PageTitle from '../title/PageTitle'

// Utils
import {
  getEditionName,
  getFractalId,
  getIconByOlympiadNameNoTitle,
  getOlympiadName,
  getYear
} from '../table/functions';
interface Props {
  data: any
  isFetching: boolean
}

const Input = styled('input')(({ theme }) => ({
  background: '#F6F2FF',
  border: 'none',
  width: '100%',
  height: '54px',
  '&:focus': {
    border: 'none',
    outline: 'none'
  },
  '&::placeholder': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    width: '100%',
  }
}))
const Image = styled('img')(() => ({
  width: '20px'
}))

const ListWithSearch = ({ data, isFetching }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')
  const [
    sortType,
    // setSortType
  ] = useState<'year' | 'alphabetical'>('year')

  const filteredRows = data.filter((row: any) => {
    return (row?.key?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
  }
  )
  const sortedRows = filteredRows.sort((a: any, b: any) => {
    if (sortType === 'alphabetical') {
      return a?.key?.localeCompare(b?.key)
    } else {
      const inputA = a?.key
      const inputB = b?.key
      const yearA = getYear(inputA)
      const yearB = getYear(inputB)
      return +yearB - +yearA
    }
  })

  // const toggleSort = () => {
  //   setSortType((prevSortType) => (prevSortType === 'year' ? 'alphabetical' : 'year'))
  // }

  return (
    <Box
      sx={{
        borderRadius: '24px',
        background: '#fff',
        padding: '32px 24px',
        height: '100%',
        maxHeight: '865px',

      }}
    >
      {
        isFetching ? (<LinearProgress />) : (
          <>
            {

              data?.length === 0 ? (
                <Typography
                  sx={{
                    color: theme.palette.text.primary,
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '48px'
                  }}
                >
                  Você não possui certificados.
                </Typography>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <PageTitle title='Minhas Olimpíadas' />
                    {/* <Button onClick={toggleSort}>
                <OrderIcon />
              </Button> */}
                  </Box>
                  <Box
                    sx={{
                      alignItems: 'center',
                      background: '#F6F2FF',
                      borderRadius: '32px',
                      display: 'flex',
                      marginTop: '16px',
                      height: '54px',
                      padding: '5px 26px',
                      width: '100%',
                      '&:hover': {
                        outline: `2px solid ${theme.palette.primary.main}`
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        width: '100%'
                      }}
                    >
                      <Image src={SearchIcon} />
                      <Input
                        type='text'
                        placeholder='Pesquise por sua olimpíada'
                        value={searchTerm}
                        onChange={(e: any) => setSearchTerm(e.target.value)}
                      />
                    </Box>
                  </Box>
                  <List
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      marginTop: '32px',
                      height: '100%',
                      maxHeight: '688px',
                      overflowY: 'auto',
                      paddingRight: '10px'
                    }}
                  >
                    {sortedRows.map((row: any) => {
                      const input = row?.key
                      const year = getYear(input)
                      const editionName = getEditionName(input)
                      const olympiadName = getOlympiadName(input)
                      const fractalId = getFractalId(input)
                      return (
                        <ListItem
                          key={row.id}
                          onClick={() => navigate(`/olympiad-details/${fractalId}`, { state: row })}
                          sx={{
                            background: '#F6F2FF',
                            borderRadius: '24px',
                            padding: '20px 16px',
                            cursor: 'pointer',
                            '&:hover': {
                              background: '#FED6BF'
                            }
                          }}
                        >
                          <Button
                            sx={{

                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '0',
                              width: '100%',
                              '&:hover': {
                                background: 'none'
                              }
                            }}
                          >
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: '16px'
                              }}
                            >
                              {getIconByOlympiadNameNoTitle(row.key)}
                              <Typography
                                sx={{
                                  color: theme.palette.text.primary,
                                  fontSize: '20px',
                                  fontWeight: 600
                                }}
                              >
                                {olympiadName} - Edição: {editionName}
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                alignItems: 'center',
                                color: theme.palette.text.primary,
                                display: 'flex',
                                fontWeight: 500,
                                fontSize: '16px',
                                gap: '4px'
                              }}
                            >
                              <CalendarIcon />
                              {year}
                            </Typography>
                          </Button>
                        </ListItem>
                      )
                    })}
                  </List>
                </>
              )
            }
          </>
        )

      }
    </Box >
  )
}

export default ListWithSearch
