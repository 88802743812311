import React from 'react'

import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'
import HistoryDesktopContainer from '../containers/history/HistoryDesktopContainer'
import HistoryMobileContainer from '../containers/history/HistoryMobileContainer'

const HistoryPage = () => {

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {
        smDown ? (
          <HistoryMobileContainer />
        ) : (
          <HistoryDesktopContainer />
        )
      }
    </>
  )
}

export default HistoryPage