import { AnyAction, combineReducers } from 'redux'
import { all } from 'redux-saga/effects'

// Reducers
import authReducer from './auth/reducer'
import userOlympiadData from './UserOlympiadData/reducer'

// Sagas
import authSagas from './auth/sagas'
import userOlympiadDataSagas from './UserOlympiadData/sagas'

export function * rootSaga () {
  yield all([
    authSagas(),
    userOlympiadDataSagas()
  ])
}

export const appReducer = combineReducers({
  auth: authReducer,
  userOlympiadData
})

const rootReducer = (state:any, action: AnyAction) => {
  if (action.type === 'app:RESET_STORE') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer