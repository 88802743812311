import React from 'react'

// Assets
import Megaphone from '../../assets/sidebar/banner-megaphone.png'
import LogoVertere from '../../assets/sidebar/logo-vertere.svg'

// Components
import { Box, Typography, useMediaQuery } from '@mui/material'

// Styles
import { styled, useTheme } from '@mui/material/styles'
import { useDrawerContext } from '../../contexts/DrawerContext'

const Image = styled('img')(() => ({
  position: 'absolute',
  zIndex: 0,
  top: '-94px',
  left: '50%',
  transform: 'translateX(-50%)'

}))

const SidebarBanner = () => {
  const theme = useTheme()
  const { isDrawerOpen } = useDrawerContext()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {
        !isDrawerOpen ? (
          <Box
            display='flex'
            justifyContent='center'
            marginTop='120px'
          >
            <Typography
              component='a'
              href='https://institutovertere.org'
              target='_blank'
            >
              <img src={LogoVertere} />
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              position: 'relative',
              marginInline: '26px'
            }}
          >
            {!smDown && <Image src={Megaphone} alt='Pessoa segurando um megafone' />}
            <Box
              sx={{
                background: theme.palette.primary.main,
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '24px 8px',
                position: 'relative',
              }}
            >
              {
                smDown ? (
                  <Typography
                    component='h3'
                    sx={{
                      color: '#fff',
                      fontSize: '20px',
                      fontWeight: 600,
                      lineHeight: '120%',
                      marginBottom: '8px',
                      textAlign: 'center',
                    }}
                  >
                    Explore Vertere
                  </Typography>
                ) : (
                  <Typography
                    component='h3'
                    sx={{
                      color: '#fff',
                      fontSize: '20px',
                      fontWeight: 600,
                      lineHeight: '120%',
                      marginBottom: '8px',
                      textAlign: 'center',
                    }}
                  >
                    Explore <br /> Vertere
                  </Typography>
                )
              }
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '150%',
                  marginBottom: '24px',
                  textAlign: 'center',
                }}
              >
                Descubra novos horizontes: conheça o Instituto Vertere.
              </Typography>
              <Typography
                component='a'
                href='https://institutovertere.org'
                target='_blank'
                sx={{
                  background: '#fff',
                  borderRadius: '56px',
                  color: theme.palette.primary.main,
                  fontWeight: 500,
                  padding: '15px 24px',
                  textAlign: 'center',
                  textDecoration: 'none',
                  transition: '0.3s background ease-in-out, 0.3s color ease-in-out',
                  '&:hover': {
                    color: '#fff',
                    background: theme.palette.primary.light
                  }
                }}
              >
                Saiba mais
              </Typography>
            </Box>
          </Box >
        )
      }
    </>
  )
}

export default SidebarBanner