import React from 'react'

// Components
import { Grid, Typography } from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
// import ListSearchActiveOlympiads from '../../components/list/ListSearchActiveOlympiads'
import ListWithSearch from '../../components/list/ListSearchMyOlympiads'
// import AwardsCard from '../../components/card/AwardsCard'
import LightText from '../../components/text/LightText'

// Styles
import { useTheme } from '@mui/material/styles'

import { useAppSelector } from '../../store/hooks'



const HomeDesktopContainer = () => {

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const { data, isFetching } = useAppSelector((state) => state.userOlympiadData)

  return (
    <Box>
      <Grid
        container
        spacing={'16px'}
        sx={{
          flexDirection: mdDown ? 'column-reverse' : ''
        }}
      >
        <Grid item md={12} lg={12}>
          <Box
            sx={{
              marginTop: '50px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}
          >
            <Typography
              sx={{
                color: theme.palette.text.primary,
                fontSize: '40px',
                fontWeight: 700,
                lineHeight: '48px'
              }}
              component='h1'
            >
              Liga das Olimpíadas
            </Typography>
            <LightText
              fontSize='16px'
              text='Gerencie suas participações, imprima certificados, e acompanhe o andamento das competições.'
            />
          </Box>
          <Box
            sx={{
              paddingTop: '32px'
            }}
          >
            <ListWithSearch isFetching={isFetching} data={data} />
          </Box>
        </Grid>
        {/* <Grid item md={6} lg={6}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <AwardsCard participation={30} participationPercentage={100} />
          </Box>
          <Box
            sx={{
              MarginTop: '40px'
            }}
          >
            <ListSearchActiveOlympiads data={rows} />
          </Box>
        </Grid> */}

      </Grid>
    </Box>
  )
}

export default HomeDesktopContainer