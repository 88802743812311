import React from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { PersistGate } from 'redux-persist/es/integration/react'
import { Provider } from 'react-redux'

import { CssBaseline } from '@mui/material'
import { store ,persistor } from './store/configureStore';


const onBeforeLift = () => {
  // take some action before the gate lifts
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <PersistGate loading={null} onBeforeLift={onBeforeLift} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

