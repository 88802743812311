import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any
  }
  interface Theme {
    status: {
      danger: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}

export const themeDefault = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h5: {
      fontWeight: 600,
      fontSize: 18
    }
  },
  palette: {
    primary: {
      main: process.env.REACT_APP_PROJECT_COLOR_PRIMARY || '#FE5E00',
      light: process.env.REACT_APP_PROJECT_COLOR_PRIMARY_VARIANT || '#6025E1'
    },
    secondary: {
      main: process.env.REACT_APP_PROJECT_COLOR_SECONDARY || '#6025E1',
      light: process.env.REACT_APP_PROJECT_COLOR_SECONDARY_VARIANT || '#AF91EF'
    },
    error: {
      main: process.env.REACT_APP_PROJECT_COLOR_SECONDARY_VARIANT || '#F69E9E',
      light: process.env.REACT_APP_PROJECT_COLOR_SECONDARY_VARIANT || 'rgb(246, 158, 158, 0.7)'
    },
    text: {
      primary: process.env.REACT_APP_PROJECT_COLOR_SECONDARY_VARIANT || '#3F3E3E',
      secondary: process.env.REACT_APP_PROJECT_COLOR_SECONDARY_VARIANT || '#9E9E9E'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 906,
      lg: 1241,
      xl: 1440
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        viewTransitionContainer: {
          height: 250,
          overflowY: 'hidden'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '& Mui-selected': {
            background: process.env.REACT_APP_PROJECT_COLOR_SECONDARY || '#04AEC9',
          }
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          '& .MuiPaginationItem-previousNext': {
            backgroundColor: '#d9d9d966'
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '7px',
          height: 6,
          '& .MuiLinearProgress-bar': {
            borderRadius: '7px'
          }
        }
      }
    }
  }
})

export default function GlobalTheme({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={themeDefault}>
      {children}
    </ThemeProvider>
  )
}
