import React from 'react'

// Context
import { useDrawerContext } from '../../contexts/DrawerContext'

// Components
import Sidebar from '../sidebar/Sidebar'
import { Box, useMediaQuery } from '@mui/system'
import Header from '../header/Header'

// Styles
import { useTheme } from '@mui/material/styles'

const DashboardTemplate = ({ children }: any) => {
  const theme = useTheme()
  const { isDrawerOpen } = useDrawerContext()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{ display: 'flex', padding: smDown ? 0 : '20px' }}>
      <Box
        position='relative'
      >
        <Sidebar />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          paddingLeft: smDown ? 0 : isDrawerOpen ? '20px' : '20px',
        }}>
        {smDown && <Header />}
        <Box flex={1} padding={0} marginTop={smDown ? '64px' : 0}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardTemplate