import React, { ChangeEvent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Assets 
import CachedRoundedIcon from '@mui/icons-material/CachedRounded'
import { ReactComponent as CalendarIcon } from '../../assets/history/table/calendar-icon.svg'

// Components
import { Box } from '@mui/system'
import PageTitle from '../../components/title/PageTitle'
import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { DatePickerField } from '../../components/inputs/DatePicker'
import { CellData, CellDocs, CellTitle } from '../../components/table/Cell'
import { RowTitle } from '../../components/table/Row'

// Utils
import {
  format,
  formatDistance
} from 'date-fns'
import { scrollToTop } from '../../utils/functions'

// Styles
import { getIconByDocumentName, getIconByOlympiadName } from '../../components/table/functions'
import { rows } from '../../utils/mockup'

const HistoryDesktopContainer = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDate, setStartDate] = useState<Date | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endDate, setEndDate] = useState<Date | null>(null)

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleStartChanges = (startValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)
      // ACTION_TO_CALL({
      //   start_from: startDateFormatted,
      //   start_until: null,
      //   page: 1
      // })
    } else {
      // ACTION_TO_CALL({
      //   start_from: startDateFormatted,
      //   page: 1
      // })
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    // ACTION_TO_CALL({ start_until: endDateFormatted, page: 1 })
    resetLocation()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClearDates = () => {
    setStartDate(null)
    setEndDate(null)
    setPage(1)
    // dispatch(activitiesItemsFetchRequest({ start_from: null, start_until: null, page: 1 }))
    resetLocation()
  }

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    // dispatch(ACTION_TO_CALL({ per: perPage, page: value }))
    scrollToTop()
  }


  return (
    <Box
      sx={{
        paddingTop: '90px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: '50px'
        }}
      >
        <PageTitle title='Histórico de Olimpíadas' />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '8px'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '120%',
              whiteSpace: 'nowrap'
            }}
          >
            Iniciam entre:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '24px'
            }}
          >
            <DatePickerField
              name='start_from'
              onChange={handleStartChanges}
              startDate={startDate}
              endDate={endDate}
              placeholder='dd/mm/aaaa'
              selectsStart
            />
            <DatePickerField
              name='start_until'
              onChange={handleEndChanges}
              startDate={endDate}
              endDate={endDate}
              minDate={startDate}
              placeholder='dd/mm/aaaa'
            />
          </Box>
          <Button
            type='button'
            onClick={handleClearDates}
          >
            <CachedRoundedIcon />
          </Button>
        </Box>
      </Box>
      <Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 794,
              borderCollapse: 'separate',
              borderSpacing: '0px 16px'
            }}
            aria-label='simple table'
          >
            <TableHead>
              <TableRow
                sx={{
                  '& td, & th': { border: 0 }
                }}
              >
                <RowTitle>Olimpíada</RowTitle>
                <RowTitle align='center'>Edição</RowTitle>
                <RowTitle align='center'>Documentos</RowTitle>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    key={row.name}
                    sx={{
                      '& td, & th': { border: '0px solid transparent' },
                      '& > .MuiTableCell-root': {
                        background: '#FFFFFF'
                      }
                    }}
                  >
                    <CellTitle
                      sx={{
                        borderTopLeftRadius: '24px',
                        borderBottomLeftRadius: '24px',
                      }}
                      component='th'
                      scope='row'
                    >
                      {getIconByOlympiadName(row.name)}
                    </CellTitle>
                    <CellData
                      align='center'>
                      <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '4px'
                      }}
                      >
                        <CalendarIcon />
                        <Typography>
                          {row.edition}
                        </Typography>
                      </Box>
                    </CellData>
                    <CellDocs>
                      {
                        row.documents.map((document, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                alignContent: 'center',
                                borderRadius: '10px',
                                background: '#F2ECFC',
                                display: 'flex',
                                padding: '8px 9px'
                              }}
                            >
                              {getIconByDocumentName(document.name)}
                            </Box>
                          )
                        })
                      }
                    </CellDocs>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        count={100}
        size='small'
        color='secondary'
        page={page}
        onChange={handleChangePage}
        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}
      />
    </Box>
  )
}

export default HistoryDesktopContainer