import { persistStore, persistReducer } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'

import rootReducer, { rootSaga } from './index'
import { addTokenToRequest, onAuthErrors } from './middlewares'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'persistable']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = createSagaMiddleware()

const configurestore = ({ initialState }: any = {}) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware, addTokenToRequest, onAuthErrors] as const,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production'
  })
  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}
export const {store, persistor} = configurestore()

export type RootState = ReturnType<typeof persistedReducer>
export type AppDispatch = typeof store.dispatch
