import React, { useEffect } from "react"

// Components
import HomeMobileContainer from '../containers/home/HomeMobileContainer'
import HomeDesktopContainer from '../containers/home/HomeDesktopContainer'

// Redux
import { useDispatch } from 'react-redux'
import { getUserDataRequest } from '../store/auth/actions'
import { getUserOlympiadDataByFractalIdRequest } from '../store/UserOlympiadData/action'
import { useAppSelector } from '../store/hooks'

// Styles
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'

const Home = () => {

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { user } = useAppSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getUserDataRequest())
  }, [])

  useEffect(() => {
    user.fractal_id && dispatch(getUserOlympiadDataByFractalIdRequest({ fractalId: user.fractal_id }))
  }, [user.fractal_id])

  return (
    <>
      {
        smDown ? (
          <HomeMobileContainer />
        ) : (
          <HomeDesktopContainer />
        )
      }
    </>
  )
}

export default Home