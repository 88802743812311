import React from 'react'

// Assets
import { ReactComponent as CircledArrowIcon } from '../../assets/button/circled-arrow-icon.svg'

// Components
// import { Box } from '@mui/system'

// Styles
import {
  // useTheme,
  styled
} from '@mui/material/styles'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface Props {
  path: string
}

const CustomButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  background: 'transparent',
  border: 'none',
  color: theme.palette.text.primary,
  cursor: 'pointer',
  display: 'flex',
  gap: '8px',
  'p': {
    fontSize: '28px',
    color: theme.palette.text.primary,
    fontWeight: 600
  }

}))

const BackButton = ({ path }: Props) => {

  const navigate = useNavigate()
  return (
    <CustomButton
      onClick={() => navigate(path)}
    >
      <CircledArrowIcon />
      <Typography>Voltar</Typography>
    </CustomButton>
  )
}

export default BackButton