/* eslint-disable camelcase */
import { createReducer } from '@reduxjs/toolkit'
import { StateType } from './types'
import * as actions from './actions'
import { AnyAction } from 'redux-saga'


const initialState: StateType = {
  user: {
    id: null,
    name: '',
    email: '',
    first_access: false,
    active: false,
    access_type: null,
    genre: '',
    birthday: '',
    phone: '',
    photo_url: '',
    token: '',
    fractal_id: null,
    children: [],
    facebook_uuid: '',
    taxpayer_number: '',
    additional_data: {
      address: {
        number: '',
        street: '',
        zipcode: '',
        complement: '',
        neighborhood: '',
      },
      mother_name: '',
      special_time: '',
      accepted_terms: ''
    },
    units: [],
    last_access_at: '',
    filters: '',
    user_phrase: ''
  },
  credentials: '',
  isLogged: false,
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const auth = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getUserDataRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getUserDataSuccess, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      user: action.payload,
    }))
    .addCase(actions.getUserDataRejected, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: ''
    }))
    .addCase(
      actions.SIGN_IN, (state: StateType): StateType => ({
        ...state,
        isFetching: true,
        isLogged: false,
        isError: false,
        errorMessage: ''
      })
    )
    .addCase(
      actions.SIGN_IN_SUCCESSFUL, (state: StateType, action: { payload: any }): StateType => ({
        ...state,
        isFetching: false,
        isLogged: true,
        isError: false,
        user: action.payload,
        errorMessage: ''
      })
    )
    .addCase(
      actions.SIGN_IN_FAILURE, (state: StateType, action: { payload: any }): StateType => ({
        ...state,
        isFetching: false,
        isLogged: false,
        user: {
          ...state.user
        },
        isError: true,
        errorMessage: action.payload
      })
    )
    .addCase(
      actions.SIGN_OUT_SUCCESS, (): StateType => ({
        ...initialState
      })
    )
    .addCase(
      actions.updateCredentialsSuccess, (state: StateType, action: AnyAction): StateType => {
        return ({
          ...state,
          isLogged: true,
          user: action.payload,
          credentials: action.payload.token
        })
      }
    )
})

export default auth
