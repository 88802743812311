import http from '../../utils/http'
import { AxiosResponse } from 'axios'

// Endpoints
const signInEndpoint = '/auth'
const usersEndpoint = '/users'

type credentials = {
    token: string;
};

export const getUserDataService = () => {
  return http.get(usersEndpoint)
}
export const signIn = (credentials: credentials) => {
  return http
    .post(`${signInEndpoint}/sign_in`, {
      datagateway_token: credentials.token
    })
    .then((res: AxiosResponse) => res)
}

export const signOut = () => {
  return http.post(`${usersEndpoint}/logout`).then((res: AxiosResponse) => res)
}
