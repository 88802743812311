import React from 'react'

// Assets
import Logo from '../assets/loginPage/logo.svg'

// Components
import Button from '@mui/material/Button'
import { Box, Typography } from '@mui/material'

// Styles
import { styled } from '@mui/material/styles'
import LoginPageStyle from './styles/LoginPageStyle.module.scss'

// Utils
import { ssoUrl, olysLeagueAppUrl } from '../utils/constants'
import { encodeQueryData } from '../utils/functions'

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '32px',
  color: '#fff',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '100%',
  textTransform: 'capitalize',
  padding: '11px 24px',
  width: 'fit-content',
  '&:hover': {
    backgroundColor: '#ffa60073'
  }
}))

const NonLoggedPage = () => {
  const {
    REACT_APP_SERVER_ENV = 'local',
    REACT_APP_API_ENV = REACT_APP_SERVER_ENV,
    REACT_APP_SSO_ENV = REACT_APP_SERVER_ENV,
    REACT_APP_PROJECT_ID = 58,
    REACT_APP_URL_STAGING
  } = process.env

  const urlRedirect = `${REACT_APP_URL_STAGING || olysLeagueAppUrl[REACT_APP_API_ENV]}/login`
  const data = {
    app_id: REACT_APP_PROJECT_ID,
    url_redirect: urlRedirect
  }
  const urlString = encodeQueryData(data)
  const url = `${ssoUrl[REACT_APP_SSO_ENV]}?${urlString}`

  return (
    <div className={LoginPageStyle.container}>
      <Box className={LoginPageStyle.wrapper}>
        <img className={LoginPageStyle.logo} src={Logo} />
        <Typography className={LoginPageStyle.title}>
          Bem-vindo à Liga das Olimpíadas
        </Typography>
        <Typography className={LoginPageStyle.text}>
          Gerencie todas as suas olimpíadas do conhecimento em um único lugar. Acesse certificados, descubra novas competições e acompanhe seu progresso de forma fácil e intuitiva.
        </Typography>
        <ButtonStyled
          href={url}
        >
          Fazer Login
        </ButtonStyled>
      </Box>
    </div>
  )
}

export default NonLoggedPage