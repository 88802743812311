import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'

interface getUserOlympiadDataByFractalIdRequestPayload {
  fractalId: number | null
}

export const getUserOlympiadDataByFractalIdRequest = createAction(
  'users:GET_USER_OLYMPIAD_DATA_BY_FRACTAL_ID_REQUEST',
  withPayloadType<getUserOlympiadDataByFractalIdRequestPayload>()
)
export const getUserOlympiadDataByFractalIdSuccess = createAction(
  'users:GET_USER_OLYMPIAD_DATA_BY_FRACTAL_ID_SUCCESS',
  withPayloadType<any>()
)
export const getUserOlympiadDataByFractalIdRejected = createAction(
  'users:GET_USER_OLYMPIAD_DATA_BY_FRACTAL_ID_REJECTED',
  withPayloadType<any>()
)