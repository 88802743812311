import React from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
interface props {
    title: string
    fontSize?: string
}

const PageTitle = ({ title, fontSize }: props) => {

    const theme = useTheme()
    return (
        <Typography
            sx={{
                color: theme.palette.text.primary,
                fontSize: fontSize ?? '28px',
                fontWeight: 600,
                lineHeight: '120%'
            }}
            component='h1'
        >
            {title}
        </Typography>
    )
}

export default PageTitle