
export const rows = [
  {
    id: 1,
    name: 'Olimpíada Pocket',
    description: 'A Olimpíada Pocket é uma série de olimpíadas sobre temas específicos que acontecem ao longo do ano letivo: pautas da ordem do dia (como o COVID-19), comemorativas (como algum marco histórico) ou relevantes cultural e cientificamente tornam-se os protagonistas desses processos olímpicos pontuais. Grandes eventos, autores e obras clássicas da história, debates contemporâneos... todos estes temas são passíveis de se transformar em mais um processo de ensino e aprendizagem.',
    active: false,
    edition: '2021',
    inscriptions: [
      {
        start_date: '05/05/2024',
        end_date: '05/10/2024'
      }
    ],
    phases: [
      {
        name: 'Fase 01',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },
      {
        name: 'Fase 02',
        start_date: '05/05/2024',
        end_date: '05/05/2024'

      },
      {
        name: 'Fase 03',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },

    ],
    documents: [
      {
        name: 'syllabus',
        size: '443KB'
      },
      {
        name: 'regulamentação',
        size: '443KB'
      },
      {
        name: 'prova',
        size: '443KB'
      },
    ]
  },
  {
    id: 2,
    name: 'Sapientia - Olimpíada do Futuro',
    description: 'Sapientia desde 2010 .... Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae ducimus ipsam magnam quia cupiditate corporis provident possimus nisi asperiores vel, quam facere ipsa nam laudantium deserunt fuga sequi, itaque nemo!',
    active: true,
    edition: '2024',
    inscriptions: [
      {
        start_date: '05/05/2024',
        end_date: '05/10/2024'
      }
    ],
    phases: [
      {
        name: 'Fase 01',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },
      {
        name: 'Fase 02',
        start_date: '05/05/2024',
        end_date: '05/05/2024'

      },
      {
        name: 'Fase 03',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },

    ],
    documents: [
      {
        name: 'gabarito',
        size: '443KB'
      },
      {
        name: 'prova',
        size: '443KB'
      },
    ]
  },
  {
    id: 3,
    name: 'Vitalis Olimpíada de Medicina',
    description: 'Vitalis desde 2009 .... Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae ducimus ipsam magnam quia cupiditate corporis provident possimus nisi asperiores vel, quam facere ipsa nam laudantium deserunt fuga sequi, itaque nemo!',
    active: false,
    edition: '2022',
    inscriptions: [
      {
        start_date: '05/01/2023',
        end_date: '05/05/2023'
      }
    ],
    phases: [
      {
        name: 'Fase 01',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },
      {
        name: 'Fase 02',
        start_date: '05/05/2024',
        end_date: '05/05/2024'

      },
      {
        name: 'Fase 03',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },

    ],
    documents: [
      {
        name: 'syllabus',
        size: '443KB'
      },
      {
        name: 'regulamentação',
        size: '443KB'
      },
      {
        name: 'certificado',
        size: '443KB'
      },
      {
        name: 'prova',
        size: '443KB'
      },

    ]
  },
  {
    id: 4,
    name: 'OBL - Olimpíada Brasileira de Linguística',
    description: 'OBL desde 2011 ... Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae ducimus ipsam magnam quia cupiditate corporis provident possimus nisi asperiores vel, quam facere ipsa nam laudantium deserunt fuga sequi, itaque nemo!',
    active: false,
    edition: '2023',
    inscriptions: [
      {
        start_date: '05/01/2022',
        end_date: '05/05/2022'
      }
    ],
    phases: [
      {
        name: 'Fase 01',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },
      {
        name: 'Fase 02',
        start_date: '05/05/2024',
        end_date: '05/05/2024'

      },
      {
        name: 'Fase 03',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },

    ],
    documents: [
      {
        name: 'syllabus',
        size: '443KB'
      },
      {
        name: 'regulamentação',
        size: '443KB'
      },
      {
        name: 'certificado',
        size: '443KB'
      },
      {
        name: 'gabarito',
        size: '443KB'
      },
      {
        name: 'prova',
        size: '443KB'
      },

    ]
  },
  {
    id: 5,
    name: 'Olimpíada Pocket',
    description: 'A Olimpíada Pocket é uma série de olimpíadas sobre temas específicos que acontecem ao longo do ano letivo: pautas da ordem do dia (como o COVID-19), comemorativas (como algum marco histórico) ou relevantes cultural e cientificamente tornam-se os protagonistas desses processos olímpicos pontuais. Grandes eventos, autores e obras clássicas da história, debates contemporâneos... todos estes temas são passíveis de se transformar em mais um processo de ensino e aprendizagem.',
    active: true,
    edition: '2023',
    inscriptions: [
      {
        start_date: '01/01/2024',
        end_date: '30/10/2025'
      }
    ],
    phases: [
      {
        name: 'Fase 01',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },
      {
        name: 'Fase 02',
        start_date: '05/05/2024',
        end_date: '05/05/2024'

      },
      {
        name: 'Fase 03',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },

    ],
    documents: [
      {
        name: 'syllabus',
        size: '443KB'
      },
      {
        name: 'regulamentação',
        size: '443KB'
      },
      {
        name: 'certificado',
        size: '443KB'
      },
      {
        name: 'gabarito',
        size: '443KB'
      },
      {
        name: 'prova',
        size: '443KB'
      },

    ]
  },
  {
    id: 6,
    name: 'OBL - Olimpíada Brasileira de Linguística',
    description: 'Desde 2011, a Olimpíada Brasileira de Linguística desenvolve habilidades lógico-analíticas e interculturais, engajando estudantes e o público... Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae ducimus ipsam magnam quia cupiditate corporis provident possimus nisi asperiores vel, quam facere ipsa nam laudantium deserunt fuga sequi, itaque nemo!',
    active: false,
    edition: '2020',
    inscriptions: [
      {
        start_date: '05/01/2022',
        end_date: '05/05/2022'
      }
    ],
    phases: [
      {
        name: 'Fase 01',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },
      {
        name: 'Fase 02',
        start_date: '05/05/2024',
        end_date: '05/05/2024'

      },
      {
        name: 'Fase 03',
        start_date: '05/05/2024',
        end_date: '05/05/2024'
      },

    ],
    documents: [
      {
        name: 'syllabus',
        size: '443KB'
      },
      {
        name: 'regulamentação',
        size: '443KB'
      },
      {
        name: 'certificado',
        size: '443KB'
      },
      {
        name: 'gabarito',
        size: '443KB'
      },
      {
        name: 'prova',
        size: '443KB'
      },

    ]
  },
]

