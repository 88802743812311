
import { Typography } from '@mui/material'
import React from 'react'

interface Props {
  name: string
}

const SidebarUserName = (props: Props) => {
  return (
    <Typography
      sx={{
        color: '#fff',
        fontWeight: 600,
        fontSize: '18px'
      }}
    >
      {props.name}
    </Typography>
  )
}

export default SidebarUserName