/* eslint-disable import/no-duplicates */
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDrawerContext } from '../../contexts/DrawerContext'

// Assets
import LogoFull from '../../assets/sidebar/logo-full.png'
import LogoSmall from '../../assets/sidebar/logo-small.png'
import { ReactComponent as HomeIcon } from '../../assets/sidebar/home-icon.svg'
import LogoutIcon from '../../assets/sidebar/logout-icon.svg'
// import { ReactComponent as HistoryIcon } from '../../assets/sidebar/history-icon.svg'

// Components
import { Box } from '@mui/system'
import {
  Avatar,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery
} from '@mui/material'
import SidebarUserName from './SidebarUserName'
import SidebarEditUser from './SidebarEditUser'
import SidebarBanner from './SidebarBanner'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { SIGN_OUT_REQUEST } from '../../store/auth/actions'

// Style
import { CSSObject, styled, useTheme } from '@mui/material/styles'

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.spacing(33),
  borderRadius: '16px',
  height: '100%',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  borderRadius: '16px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  height: '100%',
  width: `calc(${theme.spacing(10)} + 1px)`,
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  gap: '12px',
  justifyContent: 'center',
  padding: '8px 8px 20px',
  ...theme.mixins.toolbar,
}))

const DrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexShrink: 0,
    // whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      background: theme.palette.primary.light,
      height: '100% !important',
      position: 'relative',
      bottom: 0,
      left: 0,
      top: 0,
      right: 0
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface IMenuItemLinkProps {
  label: string
  icon: string | React.ReactNode
  to: string
  onClick: (() => void) | undefined
}

const MenuItemLink: React.FC<IMenuItemLinkProps> = ({ to, label, icon, onClick }) => {
  const { isDrawerOpen } = useDrawerContext()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(to)
    onClick?.()
  }

  return (
    <ListItemButton
      onClick={handleClick}
    >
      <ListItemIcon>
        <Icon
          sx={{ fontSize: isDrawerOpen ? '20px' : '32px' }}
        >
          {icon}
        </Icon>
      </ListItemIcon>
      <ListItemText
        primary={label}
        sx={{
          color: '#fff',
          fontSize: '18px',
          '& > span': {
            fontWeight: 500,
          }
        }}
      />
    </ListItemButton>
  )
}

const Sidebar = () => {
  const { user } = useSelector((state: any) => state.auth)
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isDrawerOpen, toggleDrawerOpen } = useDrawerContext()

  const location = useLocation()
  const path = location?.pathname

  return (
    <>
      {!smDown && (
        <DrawerStyled open={isDrawerOpen} variant={smDown ? 'temporary' : 'permanent'}>
          <Box
            width='100%'
            display='flex'
            flexDirection='column'
          >
            <DrawerHeader>
              <IconButton onClick={toggleDrawerOpen}>
                <Icon sx={{ color: '#fff' }}>{isDrawerOpen ? 'chevron_left' : 'chevron_right'}</Icon>
              </IconButton>
              <Box
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                gap='4px'
              >
                {
                  isDrawerOpen
                    ? (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                        width='130px'
                        src={LogoFull}
                        alt='Logo da liga das olimpíadas'
                      />
                    )
                    : (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                        width={'56px'}
                        src={LogoSmall}
                        alt='Logo da liga das olimpíadas'
                      />
                    )
                }
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  justifyContent: 'center',
                  marginTop: isDrawerOpen ? '30px' : '137px',
                  width: '100%',
                }}
              >
                <Avatar
                  onClick={toggleDrawerOpen}
                  sx={{
                    cursor: 'pointer',
                    height: isDrawerOpen ? '108px' : '56px',
                    width: isDrawerOpen ? '108px' : '56px',
                  }}
                  src={user?.photo_url}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}>
                  {
                    isDrawerOpen && <>
                      <SidebarUserName name={user?.name} />
                      <SidebarEditUser />
                    </>
                  }
                </Box>
              </Box>
            </DrawerHeader>
            <Box marginBottom='60px' flex={1}>
              <List component='nav'>
                <ListItemButton
                  sx={{
                    gap: '9px',
                    justifyContent: 'center',
                    '& .MuiListItemIcon-root': {
                      minWidth: '0px',
                    }
                  }}
                  onClick={() => navigate('/')}>
                  <ListItemIcon
                    sx={{
                      '& > svg > path': {
                        fill: path === '/' ? theme.palette.primary.main : '#fff',
                      }
                    }}
                  >
                    <HomeIcon
                      width={isDrawerOpen ? '16px' : '32px'}
                      height={isDrawerOpen ? '16px' : '32px'}
                    />
                  </ListItemIcon>
                  {
                    isDrawerOpen && <ListItemText
                      sx={{
                        color: path === '/' ? theme.palette.primary.main : '#fff',
                        '& .MuiTypography-root': {
                          fontWeight: 500
                        }
                      }}
                      primary='Home' />
                  }
                </ListItemButton>
                {/* <ListItemButton
                  sx={{
                    gap: '9px',
                    justifyContent: 'center',
                    '& .MuiListItemIcon-root': {
                      minWidth: '0px',
                    }
                  }}
                  onClick={() => navigate('/history')}>
                  <ListItemIcon
                    sx={{
                      '& > svg > path': {
                        fill: path === '/history' ? theme.palette.primary.main : '#fff',
                      }
                    }}
                  >
                    <HistoryIcon
                      width={isDrawerOpen ? '16px' : '32px'}
                      height={isDrawerOpen ? '16px' : '32px'}
                    />
                  </ListItemIcon>
                  {
                    isDrawerOpen && <ListItemText
                      sx={{
                        color: path === '/history' ? theme.palette.primary.main : '#fff',
                        '& .MuiTypography-root': {
                          fontWeight: 500
                        }
                      }}
                      primary='Histórico' />
                  }
                </ListItemButton> */}
                <ListItemButton
                  sx={{
                    gap: '9px',
                    justifyContent: 'center',
                    '& .MuiListItemIcon-root': {
                      minWidth: '0px',
                    }
                  }}
                  onClick={() => dispatch(SIGN_OUT_REQUEST())}>
                  <ListItemIcon
                    sx={{ color: '#fff' }}
                  >
                    <img
                      src={LogoutIcon}
                      width={isDrawerOpen ? '16px' : '32px'}
                    />
                  </ListItemIcon>
                  {
                    isDrawerOpen && <ListItemText
                      sx={{
                        color: '#fff',
                        '& .MuiTypography-root': {
                          fontWeight: 500
                        }
                      }}
                      primary='Sair' />
                  }
                </ListItemButton>
              </List>
            </Box>
            <Box mt='50px' mb={isDrawerOpen ? '35px' : '130px'}>
              <SidebarBanner />
            </Box>
          </Box>
        </DrawerStyled>
      )}
      {smDown && (
        <Drawer
          sx={{
            position: 'relative',
            '& .MuiPaper-root': {
              borderBottomRightRadius: '32px',
              borderTopRightRadius: '32px',
              height: '95%',
              overflowX: 'hidden',
              width: '95%',
              background: theme.palette.primary.light
            },
          }}
          open={isDrawerOpen}
          variant='temporary'
          onClose={toggleDrawerOpen}
        >
          <IconButton sx={{
            width: 'fit-content',
            alignSelf: 'end',
            margin: '10px'
          }}
            onClick={toggleDrawerOpen}
          >
            <Icon
              sx={{
                color: '#fff'
              }}
            >
              {isDrawerOpen ? 'chevron_left' : 'chevron_right'}
            </Icon>
          </IconButton>
          <Box height='100%' display='flex' flexDirection='column'>
            <Box
              width='100%'
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              gap='8px'
              padding='0px 0px 20px 0px'
            >
              <Avatar
                sx={{
                  height: '108px',
                  width: '108px'
                }}
                src={user?.photo_url}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}
              >
                <SidebarUserName name={user?.name} />
                <SidebarEditUser />
              </Box>
            </Box>
            <Divider sx={{ background: '#AF91EF', marginInline: '16px' }} />
            <Box
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
              }}
              flex={1}
            >
              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'column',

                }}
                component='nav'
              >
                <MenuItemLink
                  label='Home'
                  icon={
                    <HomeIcon

                    />
                  }
                  to='/'
                  onClick={smDown && toggleDrawerOpen}
                />
                {/* <MenuItemLink
                  label='Histórico'
                  icon={<HistoryIcon />}
                  to='/history'
                  onClick={smDown && toggleDrawerOpen}
                /> */}
                <ListItemButton onClick={() => dispatch(SIGN_OUT_REQUEST())}>
                  <ListItemIcon>
                    <img src={LogoutIcon} width={'20px'} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: '#fff',
                      '& .MuiTypography-root': {
                        fontWeight: 500
                      }
                    }}
                    primary='Sair' />
                </ListItemButton>
              </List>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  position: 'absolute',
                  bottom: '30px'
                }}
              >
                <SidebarBanner />
              </Box>
            </Box>
          </Box>
        </Drawer >
      )}
    </>
  )
}

export default Sidebar