import React from 'react'
import { Box } from '@mui/system'

import { ReactComponent as OBLIcon } from '../../assets/history/logos/obl-logo.svg'
import { ReactComponent as SapientiaLogo } from '../../assets/history/logos/sapientia-logo.svg'
import { ReactComponent as VitalisLogo } from '../../assets/history/logos/vitalis-logo.svg'
import { ReactComponent as PocketLogo } from '../../assets/history/logos/pocket-logo.svg'
import { ReactComponent as AnswerKeyIcon } from '../../assets/history/documents/answer-key-icon.svg'
import { ReactComponent as CertificateIcon } from '../../assets/history/documents/certificate-icon.svg'
import { ReactComponent as RegulationIcon } from '../../assets/history/documents/regulation-icon.svg'
import { ReactComponent as SyllabusIcon } from '../../assets/history/documents/syllabus-icon.svg'
import { ReactComponent as ExamIcon } from '../../assets/history/documents/exam-icon.svg'
import { Tooltip } from '@mui/material'

export const getIconByDocumentName = (name: string) => {
  switch (name) {
    case 'syllabus':
      return (
        <Tooltip title='Syllabus'>
          <SyllabusIcon />
        </Tooltip>
      )
    case 'regulamentação':
      return (
        <Tooltip title='Regulamento'>
          <RegulationIcon />
        </Tooltip>
      )
    case 'certificate':
      return (
        <Tooltip title='Certificado'>
          <CertificateIcon />
        </Tooltip>
      )
    case 'gabarito':
      return (
        <Tooltip title='Gabarito'>
          <AnswerKeyIcon />
        </Tooltip>
      )
    case 'prova':
      return (
        <Tooltip title='Prova'>
          <ExamIcon />
        </Tooltip>
      )
    default:
      return (
        <Tooltip title='Documento'>
          <SyllabusIcon />
        </Tooltip>
      )
  }
}

export const getIconByOlympiadName = (name: string) => {
  if (name.toLocaleLowerCase().includes('pocket')) {
    return <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '16px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          background: '#FFE166',
          borderRadius: '16px',
          display: 'flex',
          padding: '15px 8px',
          width: 'fit-content',
        }}
      >
        <PocketLogo />
      </Box>
      <Box>
        {name.toLocaleUpperCase()}
      </Box>
    </Box>
  } else if (name.toLocaleLowerCase().includes('sapientia')) {
    return <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '16px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          background: '#216FB3',
          borderRadius: '16px',
          display: 'flex',
          padding: '15px 8px',
        }}
      >
        <SapientiaLogo />
      </Box>
      <Box>
        {name.toLocaleUpperCase()}
      </Box>
    </Box>
  } else if (name.toLocaleLowerCase().includes('vitalis')) {
    return <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '16px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          background: '#555BFF',
          borderRadius: '16px',
          display: 'flex',
          padding: '15px 8px',
        }}
      >
        <VitalisLogo />
      </Box>
      <Box>
        {name.toLocaleUpperCase()}
      </Box>
    </Box>
  } else if (name.toLocaleLowerCase().includes('obl')) {
    return <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '16px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          background: '#009440',
          borderRadius: '16px',
          display: 'flex',
          padding: '15px 8px',
        }}
      >
        <OBLIcon />
      </Box>
      <Box>
        {name.toLocaleUpperCase()}
      </Box>
    </Box>
  } else {
    return ''
  }
}

export const getIconByOlympiadNameNoTitle = (name: string) => {
  if (name?.toLocaleLowerCase()?.includes('pocket')) {
    return <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '16px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          background: '#FFE166',
          borderRadius: '16px',
          display: 'flex',
          padding: '15px 8px',
          width: 'fit-content',
        }}
      >
        <PocketLogo />
      </Box>
    </Box>
  } else if (name?.toLocaleLowerCase()?.includes('sapientia')) {
    return <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '16px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          background: '#216FB3',
          borderRadius: '16px',
          display: 'flex',
          padding: '15px 8px',
        }}
      >
        <SapientiaLogo />
      </Box>
    </Box>
  } else if (name?.toLocaleLowerCase()?.includes('vitalis')) {
    return <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '16px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          background: '#555BFF',
          borderRadius: '16px',
          display: 'flex',
          padding: '15px 8px',
        }}
      >
        <VitalisLogo />
      </Box>
    </Box>
  } else if (name?.toLocaleLowerCase()?.includes('obl')) {
    return <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '16px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          background: '#009440',
          borderRadius: '16px',
          display: 'flex',
          padding: '15px 8px',
        }}
      >
        <OBLIcon />
      </Box>
    </Box>
  } else {
    return ''
  }
}

export function getYear(inputString: string) {
  const parts = inputString.split('/')
  if (parts.length < 4) {
   console.error('String incompatible') // eslint-disable-line
  }
  return parts[2]
}

export function getEditionName(inputString: string) {
  const parts = inputString.split('/')
  if (parts.length < 4) {
   console.error('String incompatible') // eslint-disable-line
  }
  return parts[3]
}

export function getOlympiadName(inputString: string) {
  const parts = inputString.split('/')
  if (parts.length < 4) {
   console.error('String incompatible') // eslint-disable-line
  }
  return parts[1]
}

export function getFractalId(inputString: string) {
  const parts = inputString.split('/')
  if (parts.length < 4) {
   console.error('String incompatible') // eslint-disable-line
  }
  return parts[0]
}

export function convertBytesToMB(bytes: number) {
  const MB = bytes / (1024 * 1024)
  return MB.toFixed(2)
}