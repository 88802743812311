import React from 'react'

// Assets
import Logo from '../../assets/notFoundPage/logo-color-2.png'
import supportOperator from '../../assets/notFoundPage/support-operator.png'

// Components
import { Box, Typography } from '@mui/material'

// styles
import { useTheme } from '@mui/material/styles'

const NotFoundPageMobile = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        alignItems: 'center',
        background: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        padding: '18px',
      }}
    >

      <img width={194} src={Logo} alt='logo' />

      <img width={341} style={{ marginTop: '24px' }} src={supportOperator} alt='Operador de suporte atentendo chamado' />
      <Typography
        sx={{
          color: '#6025E1',
          fontSize: '56px',
          fontWeight: 800,
          lineHeight: '100%',
          letterSpacing: '-3.6px',
          marginTop: '24px',
          textTransform: 'uppercase',
          textAlign: 'center'
        }}
      >
        Ooops, Algo deu Errado!
      </Typography>
      <Typography
        sx={{
          color: '#3F3E3E',
          fontSize: '16px',
          fontWeight: '500',
          marginTop: '24px'
        }}
      >
        Esta página não existe.
      </Typography>



    </Box>
  )
}

export default NotFoundPageMobile