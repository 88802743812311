import { OlysLandingPages } from './constants'

export function withPayloadType<T>() {
  return (t: T) => ({ payload: t })
}

export function encodeQueryData(data: { [key: string]: any }) {
  const ret = []
  for (const d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  }
  return ret.join('&')
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const formatDate = (dateString: string) => {
  const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
  const [day, month, year] = dateString.split("/").map(Number)
  const date = new Date(year, month - 1, day)
  const formattedDay = date.getDate()
  const formattedMonth = months[date.getMonth()]
  const formattedYear = date.getFullYear()

  return `${formattedDay} de ${formattedMonth} de ${formattedYear}`
}

export function truncateText(text: any, maxLength: any) {
  if (text.length <= maxLength) {
    return text
  } else {
    return text.substring(0, maxLength) + '...'
  }
}

export function getUrlFromText(text: any) {

  const lowerText = text?.toLowerCase();


  for (const page of OlysLandingPages) {

    if (lowerText.includes(page?.name)) {

      return page?.url
    }
  }


  return null
}