/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import { ReactComponent as OrderIcon } from '../../assets/home/ordernate-icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/history/table/calendar-icon.svg'
import SearchIcon from '../../assets/home/search-icon.svg'
import { ReactComponent as GlobeIcon } from '../../assets/home/globe-icon.svg'

// Components
import { Button, Divider, List, ListItem, Typography } from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
import PageTitle from '../title/PageTitle'
import DarkText from '../text/DarkText'

// Styles 
import { useTheme, styled } from '@mui/material/styles'

// Utils
import { getIconByOlympiadNameNoTitle } from '../table/functions';
import { getUrlFromText, truncateText } from '../../utils/functions';

interface Props {
  data: any
}

const Input = styled('input')(({ theme }) => ({
  background: '#fff',
  border: 'none',
  width: '100%',
  height: '54px',
  '&:focus': {
    border: 'none',
    outline: 'none'
  },
  '&::placeholder': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    width: '100%',
  }
}))
const Image = styled('img')(() => ({
  width: '20px'
}))

const ListSearchActiveOlympiadsMobile = ({ data }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))


  const [searchTerm, setSearchTerm] = useState('')
  const [sortType, setSortType] = useState<'year' | 'alphabetical'>('year')

  const filteredRows = data.filter((row: any) =>
    row.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const sortedRows = filteredRows.sort((a: any, b: any) => {
    if (sortType === 'alphabetical') {
      return a.name.localeCompare(b.name)
    } else {
      return b.edition - a.edition
    }
  })

  const toggleSort = () => {
    setSortType((prevSortType) => (prevSortType === 'year' ? 'alphabetical' : 'year'))
  }

  return (
    <Box
      sx={{
        borderRadius: '24px',
        background: 'transparent',
        marginTop: '32px',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >
        <PageTitle fontSize='24px' title='Olimpíadas disponíveis' />
        <Button onClick={toggleSort}>
          <OrderIcon />
        </Button>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          background: '#fff',
          borderRadius: '32px',
          display: 'flex',
          marginTop: '16px',
          height: '54px',
          padding: '5px 26px',
          width: '100%',
          '&:hover': {
            outline: `2px solid ${theme.palette.primary.main}`
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            width: '100%'
          }}
        >

          <Image src={SearchIcon} />
          <Input
            type='text'
            placeholder='Pesquise por sua olimpíada'
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
        </Box>
      </Box>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginTop: '32px',
          height: '100%',
        }}
      >
        {sortedRows.map((row: any) => {
          const url: any = getUrlFromText(row.name)
          return (

            <Box
              key={row.id}
            >
              <ListItem
                sx={{
                  background: row?.active ? '#FED6BF' : '#fff',
                  borderRadius: '24px',
                  padding: '32px 15px 45px 15px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px'
                      }}
                    >
                      {getIconByOlympiadNameNoTitle(row.name)}
                      <Typography sx={{ color: theme.palette.text.primary, fontSize: '20px', fontWeight: 600 }}>
                        {row.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px'
                      }}
                    >
                      <DarkText text={truncateText(row.description, 141)} />
                    </Box>
                    {
                      row?.active && <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          paddingTop: lgDown ? '24px' : '16px',
                          gap:'24px'
                        }}
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: '4px'
                          }}
                        >
                          <CalendarIcon />
                          <DarkText text='Inscrições até:' />
                          <Typography
                            sx={{
                              color: theme.palette.primary.main,
                              fontWeight: 500,
                              fontSize: '14px'
                            }}
                          >
                            {row?.inscriptions[0]?.end_date}
                          </Typography>
                        </Box>
                        <Button
                          sx={{
                            background: theme.palette.primary.main,
                            borderRadius: '56px',
                            color: '#fff',
                            fontSize: '16px',
                            fontWeight: 600,
                            padding: '15px 56px',
                            '&: hover': {
                              background: theme.palette.primary.light
                            },
                            width: '100%'
                          }}
                        >
                          Participar
                        </Button>

                      </Box>
                    }
                    {
                      !row?.active && <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          gap: '4px',
                          paddingTop: '30px'
                        }}
                      >
                        <GlobeIcon />
                        <Typography
                          component='a'
                          href={url}
                          target='_blank'
                          sx={{
                            color: theme.palette.primary.light,
                            textDecoration: 'none'
                          }}
                        >
                          {url}
                        </Typography>
                      </Box>
                    }
                  </Box>
                </Box>
              </ListItem>
            </Box>
          )
        })}
      </List>
    </Box >
  )
}

export default ListSearchActiveOlympiadsMobile
