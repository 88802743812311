
import React from 'react'

import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { currentHost } from '../../utils/http'
import { ssoUrl } from '../../utils/constants'


const SidebarEditUser = () => {
  const theme = useTheme()
  const handleSsoUrlRedirect = () => {
    let url
    if (currentHost.includes('staging')) {
      url = ssoUrl.staging
    } else if (!currentHost.includes('staging') && !currentHost.includes('local')) {
      url = ssoUrl.production
    } else if (currentHost.includes('local')) {
      url = ssoUrl.local
    } else {
      url = ssoUrl.staging
    }
    return url + '?app_id=58'
  }

  const ssoUrlToRedirect = handleSsoUrlRedirect()

  return (
    <Typography
      component='a'
      sx={{
        color: '#FED6BF',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 500,
        '&:hover': {
          color: theme.palette.primary.main
        },
        textDecoration: 'underline',
        textAlign: 'center',
      }}
      href={ssoUrlToRedirect}
    >
      Editar perfil
    </Typography>
  )
}

export default SidebarEditUser