import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
// import { ReactComponent as OrderIcon } from '../../assets/home/ordernate-icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/history/table/calendar-icon.svg'
import SearchIcon from '../../assets/home/search-icon.svg'

// Components
import {
  Button,
  List,
  ListItem,
  Typography,
  Icon,
  Divider,
  LinearProgress
} from '@mui/material'
import { Box } from '@mui/system'
import PageTitle from '../title/PageTitle'

// Styles 
import { useTheme, styled } from '@mui/material/styles'

// Utils
import {
  getEditionName,
  getFractalId,
  getIconByOlympiadNameNoTitle,
  getOlympiadName,
  getYear
} from '../table/functions'


interface Props {
  data: any
  isFetching: boolean
}

const Input = styled('input')(({ theme }) => ({
  background: '#fff',
  border: 'none',
  width: '100%',
  height: '54px',
  '&:focus': {
    border: 'none',
    outline: 'none'
  },
  '&::placeholder': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    width: '100%',
  }
}))
const Image = styled('img')(() => ({
  width: '20px'
}))

const ListWithSearchMobile = ({ data, isFetching }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState('')
  const [
    sortType,
    // setSortType
  ] = useState<'year' | 'alphabetical'>('year')

  const filteredRows = data.filter((row: any) => {
    return (row?.key?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
  }
  )
  const sortedRows = filteredRows.sort((a: any, b: any) => {
    if (sortType === 'alphabetical') {
      return a?.key?.localeCompare(b?.key)
    } else {
      const inputA = a?.key
      const inputB = b?.key
      const yearA = getYear(inputA)
      const yearB = getYear(inputB)
      return +yearB - +yearA
    }
  })

  // const toggleSort = () => {
  //   setSortType((prevSortType) => (prevSortType === 'year' ? 'alphabetical' : 'year'))
  // }

  return (
    <Box
      sx={{
        padding: '40px 0px',
        height: '100%',
      }}
    >
      {isFetching ? (<LinearProgress />) : <>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <PageTitle title='Minhas Olimpíadas' />
          {/* <Button onClick={toggleSort}>
          <OrderIcon />
        </Button> */}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            background: '#fff',
            borderRadius: '32px',
            display: 'flex',
            marginTop: '16px',
            height: '54px',
            padding: '5px 26px',
            width: '100%',
            '&:hover': {
              outline: `2px solid ${theme.palette.primary.main}`
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              width: '100%'
            }}
          >

            <Image src={SearchIcon} />
            <Input
              type='text'
              placeholder='Pesquise por sua olimpíada'
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
          </Box>
        </Box>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '32px',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {sortedRows.map((row: any) => {

            const input = row?.key
            const year = getYear(input)
            const editionName = getEditionName(input)
            const olympiadName = getOlympiadName(input)
            const fractalId = getFractalId(input)
            return (

              <Box
                key={row.key}

              >
                <ListItem
                  sx={{
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '16px'
                    }}
                  >
                    <Box
                      onClick={() => navigate(`/olympiad-details/${fractalId}`, { state: row })}
                    >
                      {getIconByOlympiadNameNoTitle(row.key)}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography
                        onClick={() => navigate(`/olympiad-details/${fractalId}`, { state: row })}
                        sx={{
                          color: theme.palette.text.primary,
                          fontSize: '20px',
                          fontWeight: 600
                        }}
                      >
                        {olympiadName} - Edição: {editionName}
                      </Typography>
                      <Typography
                        sx={{
                          alignItems: 'center',
                          color: theme.palette.text.secondary,
                          display: 'flex',
                          fontWeight: 500,
                          fontSize: '16px',
                          gap: '4px',
                          '& svg > path ': {
                            stroke: theme.palette.text.secondary
                          }
                        }}
                      >
                        <CalendarIcon />
                        {year}
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    onClick={() => navigate(`/olympiad-details/${fractalId}`, { state: row })}
                    sx={{
                      margin: 0,
                      padding: 0,
                      minWidth: 'fit-content',
                      position: 'absolute',
                      right: 0,
                      top: '10px',
                      width: 'fit-content',
                    }}
                  >
                    <Icon
                      sx={{
                        color: theme.palette.text.secondary
                      }}
                    >
                      {'chevron_right'}
                    </Icon>
                  </Button>
                </ListItem>
                <Divider
                  sx={{
                    background: '#CFCECE4D',
                    marginTop: '16px'
                  }}
                />
              </Box>
            )
          })}
        </List>
      </>}
    </Box >
  )
}

export default ListWithSearchMobile
