import React from 'react'

// Assets
import Logo from '../../assets/notFoundPage/logo-color-2.png'
import supportOperator from '../../assets/notFoundPage/support-operator.png'

// Components
import { Box, Typography } from '@mui/material'

// styles
import { useTheme } from '@mui/material/styles'

const NotFoundPageDesktop = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        background: theme.palette.primary.main,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '14px 45px',
        position: 'relative',
        maxWidth: '1200px',
        maxHeight: '573px'
      }}
    >
      <Box>
        <img style={{ position: 'absolute' }} src={Logo} alt='logo' />
        <Box
          sx={{
            display: 'flex',
            marginTop: '100px',
            gap: '30px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Typography
              sx={{
                color: '#6025E1',
                fontSize: '72px',
                fontWeight: 800,
                lineHeight: '100%',
                letterSpacing: '-3.6px',
                textTransform: 'uppercase',
                maxWidth: '540px'
              }}
            >
              Ooops, Algo deu Errado!
            </Typography>
            <Typography
              sx={{
                color: '#3F3E3E',
                fontSize: '16px',
                fontWeight: '500',
                marginTop: '24px'
              }}
            >
              Esta página não existe.
            </Typography>
          </Box>
          <img src={supportOperator} alt='Operador de suporte atentendo chamado' />
        </Box>
      </Box>
    </Box>
  )
}

export default NotFoundPageDesktop