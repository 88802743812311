/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Assets
import { ReactComponent as CalendarIcon } from '../../assets/history/table/calendar-icon.svg'

// Components
import { Box } from '@mui/system'
import {
  Button,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { DatePickerField } from '../../components/inputs/DatePicker'

// Utils
import {
  format,
  formatDistance
} from 'date-fns'
import {
  getIconByDocumentName,
  getIconByOlympiadNameNoTitle
} from '../../components/table/functions'
import { rows } from '../../utils/mockup'

// Styles
import { useTheme } from '@mui/material/styles'


const HistoryMobileContainer = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDate, setStartDate] = useState<Date | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endDate, setEndDate] = useState<Date | null>(null)

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleStartChanges = (startValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)
      // ACTION_TO_CALL({
      //   start_from: startDateFormatted,
      //   start_until: null,
      //   page: 1
      // })
    } else {
      // ACTION_TO_CALL({
      //   start_from: startDateFormatted,
      //   page: 1
      // })
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    // ACTION_TO_CALL({ start_until: endDateFormatted, page: 1 })
    resetLocation()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClearDates = () => {
    setStartDate(null)
    setEndDate(null)
    setPage(1)
    // dispatch(activitiesItemsFetchRequest({ start_from: null, start_until: null, page: 1 }))
    resetLocation()
  }

  return (
    <Box
      sx={{
        background: '#F0F3F4',
        padding: '20px',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '10px',
          marginBottom: '32px'
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            color: '#3F3E3E'
          }}
        >De</Typography>
        <DatePickerField
          name='start_from'
          onChange={handleStartChanges}
          startDate={startDate}
          endDate={endDate}
          placeholder='dd/mm/aaaa'
          selectsStart
        />
        <Typography>até</Typography>
        <DatePickerField
          name='start_until'
          onChange={handleEndChanges}
          startDate={endDate}
          endDate={endDate}
          minDate={startDate}
          placeholder='dd/mm/aaaa'
        />
      </Box>
      <Box>
        {
          rows.map((row, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  gap: '16px',
                  borderBottom: '1px solid #CFCECE4D',
                  paddingBlock: '24px'
                }}
              >
                <Box>
                  {getIconByOlympiadNameNoTitle(row.name)}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: 600
                      }}
                    >
                      {row.name}
                    </Typography>
                    <Button
                      sx={{
                        borderRadius: '50%',
                        padding: '5px',
                        minWidth: '34px',
                      }}
                    >
                      <Icon
                        sx={{
                          color: theme.palette.text.secondary
                        }}
                      >
                        {'chevron_right'}
                      </Icon>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '4px',
                      marginTop: '7px',
                      marginBottom: '19px',
                      width: '100%',
                      '& > svg > path': {
                        stroke: theme.palette.text.secondary
                      }

                    }}
                  >
                    <CalendarIcon />
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: '16px',
                        fontWeight: 500

                      }}
                    >
                      {row.edition}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '18px'
                    }}
                  >
                    {
                      row.documents.map((document, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              alignContent: 'center',
                              borderRadius: '10px',
                              background: '#F2ECFC',
                              display: 'flex',
                              padding: '8px 9px'
                            }}
                          >
                            {getIconByDocumentName(document.name)}
                          </Box>
                        )
                      })
                    }
                  </Box>
                </Box>
              </Box>
            )
          })
        }
      </Box>
    </Box>
  )
}

export default HistoryMobileContainer