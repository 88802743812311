import React, { useEffect } from 'react'

// Assets
// import { ReactComponent as CalendarIcon } from '../../assets/olympiadsDetails/blue-calendar-icon.svg'
// import { ReactComponent as CircledCheckedIcon } from '../../assets/olympiadsDetails/checkedIcon.svg'
import { ReactComponent as DownloadIcon } from '../../assets/olympiadsDetails/download-icon.svg'
import { ReactComponent as CertificateIcon } from '../../assets/history/documents/certificate-icon.svg'

// Components
import { Box } from '@mui/system'
import BackButton from '../../components/button/BackButton'
import PageTitle from '../../components/title/PageTitle'
// import SubTitle from '../../components/title/SubTitle'
// import LightText from '../../components/text/LightText'
import DarkText from '../../components/text/DarkText'
import {
  convertBytesToMB,
  getEditionName,
  // getIconByDocumentName,
  //  getIconByOlympiadNameNoTitle,
  getOlympiadName,
  getYear
} from '../../components/table/functions';

// Styles
import {
  useTheme,
  styled
} from '@mui/material/styles'
import {
  // Divider,
  Grid,
  Tooltip
} from '@mui/material'

// Utils
import {
  // formatDate,
  scrollToTop
} from '../../utils/functions'
import { useLocation } from 'react-router-dom'

const Button = styled('a')(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  minWidth: 'fit-content',
  padding: 0,
  '&:hover': {
    'circle': {
      fill: theme.palette.primary.main
    }
  }
}))

const OlympiadDetailsMobile = () => {

  const { state } = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()

  useEffect(() => {
    scrollToTop()
  }, [])

  const fileSize = convertBytesToMB(state?.size)
  const input = state?.key
  const year = getYear(input)
  const editionName = getEditionName(input)
  const olympiadName = getOlympiadName(input)

  return (
    <Box paddingInline='16px'>
      <Grid container spacing='36px'>
        {/* <Grid item md={6} lg={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '32px'
            }}
          >
            <Box mb='56px'>
              <BackButton path='/' />
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '8px'
              }}
            >
              {getIconByOlympiadNameNoTitle(state?.name)}
              <PageTitle title={state?.name} />
            </Box>

            <Box
              paddingBlock='24px 40px'
            >
              <DarkText
                fontSize='16px'
                text={state?.description ?? ''}
              />
            </Box>
            <Box>
              <PageTitle title={`Edição ${state?.edition}`} />
              <Box
                sx={{
                  background: '#fff',
                  borderRadius: '24px',
                  marginTop: '8px',
                  padding: '30px 15px 40px 15px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '8px',
                      marginBottom: '12px',
                      '& > svg > path': {
                        stroke: '#CFCECE'
                      }
                    }}
                  >
                    <CalendarIcon />
                    <SubTitle subTitle='Inscrições:' />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <LightText text={`Início: ${state?.inscriptions?.[0]?.start_date}`} />
                    <LightText text={`Término: ${state?.inscriptions?.[0]?.start_date}`} />
                  </Box>

                  <Divider sx={{ mt: '32px' }} />
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '8px',
                      marginTop: '32px',
                      marginBottom: '12px',
                      '& > svg > path': {
                        stroke: '#CFCECE'
                      }
                    }}
                  >
                    <CircledCheckedIcon />
                    <SubTitle subTitle='Realização:' />
                  </Box>
                  <Box>
                    {state?.phases?.map((phase: any) => {
                      return (
                        <Box key={phase?.name}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px'
                          }}
                        >
                          <DarkText text={phase.name} />
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginBottom: '8px',
                              width: '100%',
                            }}
                          >
                            <LightText text={`Início: ${formatDate(phase?.start_date)}`} />
                            <LightText text={`Término: ${formatDate(phase?.end_date)}`} />
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid> */}
        <Grid width='100%' item md={12} lg={12}>
          <Box pt='80px' mb='56px'>
            <BackButton path='/' />
          </Box>
          <Box
            sx={{

              display: 'flex',
              flexDirection: 'column',
              padding: '40px 16px',
              width: '100%'
            }}
          >
            <PageTitle title='Documentos' />
            <Box
              sx={{
                marginTop: '32px'
              }}
            >
              <DarkText textTransform='capitalize' fontSize='16px' fontWeight={600} text={`${olympiadName}:`} />
              <DarkText textTransform='capitalize' fontSize='16px' fontWeight={600} text={`${editionName} - ${year}`} />
              <Box
                sx={{
                  alignItems: 'center',
                  background: '#E4EEEF',
                  borderRadius: '16px',
                  display: 'flex',
                  marginTop: '8px',
                  padding: '15px 24px',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '14px'
                  }}
                >
                  <Box
                    sx={{
                      background: '#fff',
                      display: 'flex',
                      borderRadius: '10px',
                      padding: '9px 10px',
                      width: 'fit-content'
                    }}
                  >
                    <Tooltip title={''}>
                      <CertificateIcon />
                    </Tooltip>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px'
                  }}>
                    <DarkText text='Certificado' />
                    <DarkText text={`${fileSize} MB`} />
                  </Box>
                </Box>
                <Box>
                  <Button
                    target='_blank'
                    href={`https://cdn.fractaltecnologia.com.br/${state?.key}`}
                  >
                    <DownloadIcon />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )

}

export default OlympiadDetailsMobile