import { TableCell } from '@mui/material';
import { styled } from '@mui/system';

export const CellTitle = styled(TableCell)({
  color: '#3F3E3E',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '120%'
})

export const CellData = styled(TableCell)({
  background: '#FFFFFF',
  color: '#3F3E3E',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '120%'
})
export const CellDocs = styled(TableCell)({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  justifyContent: 'center',
  borderTopRightRadius: '24px',
  borderBottomRightRadius: '24px',
  paddingBlock: '32px'
})

