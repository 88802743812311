import React from 'react'

// Components
import DashboardTemplate from '../components/template/DashboardTemplate'
import NotFoundPageDesktop from '../containers/notFoundPage/NotFoundPageDesktop'
import NotFoundPageMobile from '../containers/notFoundPage/NotFoundPageMobile'

// styles
import { useMediaQuery } from '@mui/system'
import { useTheme } from '@mui/material/styles'


const NotFoundPage = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <DashboardTemplate>
            {smDown ? <NotFoundPageMobile/> : <NotFoundPageDesktop/>}
        </DashboardTemplate>
    )
}

export default NotFoundPage
