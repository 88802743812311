import React from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
interface props {
  text: string
  fontSize?: string
  fontWeight?: number
  textTransform?: string
}

const DarkText = ({ text, fontSize, fontWeight, textTransform }: props) => {

  const theme = useTheme()
  return (
    <Typography
      sx={{
        color: theme.palette.text.primary,
        fontSize: fontSize ?? '14px',
        fontWeight: fontWeight ?? 500,
        lineHeight: '120%',
        textTransform: textTransform ?? 'none'
      }}
      component='p'
    >
      {text}
    </Typography>
  )
}

export default DarkText